import React from "react";
import { Link } from "gatsby";
import { Layout, SEO } from '../../../components/structure';
import { Content } from "../../../components/blocks"
import styled from "styled-components";
import { brand, font, mq } from "../../../styles";

const Header = styled.div`


${ font.imports.sherika }

  width: 90%;
  max-width: 1100px;
  text-align: left;
  padding-top 80px;

  h2 {
    font-family: ${font.family.sherika};
    font-size: 56px;
    font-weight: 700;
    padding: 20px 0;
    color: ${brand.colors.pg_primary_dark};
    display: inline-block;

    .color-emphasis {
      color: ${brand.colors.pg_orange};
    }
  }

  span {
    display: inline-block;
    color: ${brand.colors.pg_primary_dark};
  }

  .date {
    display: block;
    font-family: ${font.family.sherika};
    font-weight: 700;
    font-size: 16px;
    margin: 30px 0;
  }

  p {
    color: ${brand.colors.pg_primary_dark};
    font-size: 14px;
    line-height: 21px;

    a {
      display: inline-block;
      color: ${brand.colors.pg_blue};
      cursor: pointer;
      text-decoration: underline;
    }
  }
`;

const Subsection = styled.div`
  width: 90%;
  max-width: 1100px;
  text-align: left;
  padding-bottom: 40px;

  h5 {
    font-size: 24px;
    font-weight: 600;
    color: ${brand.colors.pg_orange};
    padding: 20px 0;

    &:last-of-type {
      padding-bottom: 10px;
      margin-bottom: 0;
    }
  }

  p {
    color: ${brand.colors.pg_primary_dark};
    font-size: 16px;
    line-height: 21px;

    a {
      display: block;
      color: ${brand.colors.pg_blue};
      cursor: pointer;
      text-decoration: underline;
    }
  }

  ul {
    li {
      list-style-type: disc;
      color: ${brand.colors.pg_primary_dark};
      a {
        display: block;
        color: ${brand.colors.pg_blue};
        cursor: pointer;
        text-decoration: underline;
        font-size: 16px;
        line-height: 21px;
      }
    }
  }
`;

const TableDetails = styled.table`
display: block;
  color: ${brand.colors.pg_primary_dark};
  font-family: ${font.family.sherika};
  font-size: 20px;
    thead
      tr {
        td {
          color: ${brand.colors.pg_primary_dark};
          border-top: 1px solid hsla(0,0%,0%,0.12);
          font-weight: 600;
        }
    }

    tbody {
      tr {
        td {
          vertical-align: top;
          font-weight: 400;

          a {
            color: ${brand.colors.pg_orange};
            text-decoration: underline;
          }
        }
        td:first-of-type {
          font-weight: 700;
        }
        
      }
    }

    @media only screen and ${mq.maxMd} {
      display: none;
    }
`;

const TableDetailsMobile = styled.div`
    display: none;

    div {
      margin: 20px 0;
      span {
        color: ${brand.colors.pg_primary_dark};
        font-family: ${font.family.sherika};
        font-size: 20px;
        display: block;
        margin: 10px 0;
      }

      .purpose-con, .optout-con {
        font-family: ${font.family.sherika};
        font-size: 16px;

        a {
          text-decoration: underline;
          color: ${brand.colors.pg_orange};
        }
      }
    }

    @media only screen and ${mq.maxMd} {
      display: block;
    }
`;

const CookiePolicy = styled.div`
    width: 95%;
    max-width: 1100px;
    margin: auto;
`

const GDPRPage = () => (
  <Layout>
    <SEO title="Cookie Policy | Plusgrade" 
          description={'Politique Plusgrade Concernant les Témoins de Connexion (Cookies)'}
    />
    <Content whiteBG noMargin>
      <CookiePolicy>
        <Header>
          <h2>Politique Plusgrade <span className={"color-emphasis"}>Concernant les Témoins de Connexion (Cookies)		</span></h2>
          
          <span className={"date"}>Dernière mise à jour le 24 mai 2018</span>

          <p>
          Cette politique concernant les témoins de connexion (<strong>“Politique”</strong>) décrit les cookies que Plusgrade utilise sur notre site Internet (<strong>“Site Web”</strong>) et la plateforme du client (<strong>“Plateforme”</strong>), et les choix que vous avez. Cette politique concernant les témoins de connexion fait partie de nos Règles de Confidentialité. Veuillez s’il-vous-plaît vous référer aux <Link to={'/fr/politique/politique-de-confidentialite/'}>Règles de Confidentialité</Link> pour des informations concernant (i) les fins pour lesquelles nous utilisons vos données personnelles et notre base légale pour le traitement de ces données, (ii) comment nous protégeons vos données et (iii) vos droits et nos obligations en relation avec de telles utilisations.
          </p>

          <p>
            En choisissant d’utiliser notre Site Web ou notre Plateforme après avoir été avisé de notre utilisation de cookies dans les façons décrites dans ces Règles de Confidentialité, et, dans les juridictions applicables, par un avis et une reconnaissance non ambigu de votre consentement, vous acceptez ces formes d’utilisation.
          </p>
        </Header>
        <Subsection>
          <h5>Que sont les Cookies?</h5>
          <p>
            Un “cookie” est un bout d’information envoyé à votre navigateur par un site Internet que vous visitez. Nous utilisons les cookies pour faciliter votre utilisation de nos services durant des visites futures et pour surveiller le trafic sur notre Site Web ou Plateforme. Pour en apprendre plus sur les cookies, veuillez visiter <a style={{"display": "inline-block"}} href="http://www.allaboutcookies.org/">www.allaboutcookies.org.</a>.
          </p>
          <p>
          Certains cookies expirent après un certain bout de temps, ou lors d’une déconnexion (session cookies), d’autres restent sur votre ordinateur ou appareil pour une période plus longue (cookies persistants). Nous utilisons des cookies internes (cookies qui sont définis par Plusgrade) ainsi que des cookies tiers, décrit ci-dessous.
          </p>

          <h5>Comment Plusgrade utilise-t-il les cookies?</h5>

          <TableDetails>
          <colgroup>
            <col span="1" style={{"width": "10%"}} />
            <col span="1" style={{"width": "60%"}} />
            <col span="1" style={{"width": "30%"}} />
          </colgroup>
            <thead>
              <tr>
                <td>Type</td>
                <td>But</td>
                <td>Désinscription</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Strictement Nécessaire</td>
                <td>
                Nous utilisons des cookies qui sont strictement nécessaire afin de fournir aux utilisateurs les services disponibles à travers notre Site Web, la Plateforme et pour utiliser certaines de leurs fonctionnalités, telles que la possibilité de se connecter et d’accéder des zones sécurisées. En particulier, nous utilisons les cookies pour activer la connectivité de l’application du processus d’offre de Plusgrade, du chargement d’images et de contenus, ainsi que pour permettre aux clients de soumettre des offres sur la Plateforme. Nous utilisons également un cookie nécessaire pour enregistrer le consentement  d’un utilisateur concernant notre utilisation de ces cookies, comme décrit dans cette Politique concernant les témoins de connexion.
                </td>
                <td>
                Puisque ces cookies sont strictement nécessaires, vous ne pouvez les refuser.
                </td>
              </tr>
              <tr>
                <td>Analytique/Performance</td>
                <td>
                  <strong>Google Analytics:</strong> utilisé pour le but de gérer la performance du trafic, les interactions des clients avec le Site Web et la Plateforme et les modes d’utilisation. Votre adresse IP et autres informations collectées par ce cookie seront envoyés à et stockée par Google sur des serveurs situés aux États-Unis. <a href="https://policies.google.com/technologies/partner-sites?hl=en-GB&gl=uk/">Cliquez ici</a> pour en apprendre plus. <a href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage">Cliquez ici</a>  pour apprendre plus sur l’expiration de ces cookies.
                  <br></br>
                  <strong>Plusgrade:</strong> Nous avons mis un cookie quand vous utilisez la Plateforme afin d’aider le support à la clientèle, l’utilisation des clients et le suivi des transactions des clients. Ce cookie expirera après 365 jours.
                </td>
                <td>
                Vous pouvez bloquer ou supprimer ces cookies en changeant les paramètres du navigateur, comme expliqué dans la section “Vos Choix” ci-dessous. Vous pouvez également empêcher la collection de vos données par Google Analytics sur notre Site Web et la Plateforme en utilisant le <a href="https://tools.google.com/dlpage/gaoptout?hl=en">Google Analytics Opt-out Browser Add-on</a> pour votre navigateur actuel.
                </td>
              </tr>
              <tr>
                <td>Fonctionnalité</td>
                <td>
                Utilisé pour fournir des fonctionnements supplémentaires à notre Site Web et la Plateforme ou pour recueillir des informations supplémentaires concernant votre utilisation de notre Site Web et la Plateforme:
                  <br></br>
                  <strong>New Relic:</strong> Utilisé sur notre Plateforme pour les buts de surveiller la performance des systèmes, surveiller la latence du réseau, la duration des chargements et réponses des applications, et l’analyse de compatibilité des navigateurs. Ce cookie expire quand le navigateur/session est fermé.
                  <br></br>
                  <strong>Plusgrade:</strong> Utilisé pour la navigation du Site Web, délivrance du contenu, ou autres besoins fonctionnels.
                </td>
                <td>
                  Vous pouvez bloquer ou supprimer ces cookies en changeant les paramètres du navigateur, comme expliqué dans la section “Vos Choix” ci-dessous.
                </td>
              </tr>
            </tbody>
          </TableDetails>

          <TableDetailsMobile>
            <div className="cookie-type-con">
              <span className="type-con">
                Strictement Nécessaire
              </span>
              <span className="purpose-con">
              Nous utilisons des cookies qui sont strictement nécessaire afin de fournir aux utilisateurs les services disponibles à travers notre Site Web, la Plateforme et pour utiliser certaines de leurs fonctionnalités, telles que la possibilité de se connecter et d’accéder des zones sécurisées. En particulier, nous utilisons les cookies pour activer la connectivité de l’application du processus d’offre de Plusgrade, du chargement d’images et de contenus, ainsi que pour permettre aux clients de soumettre des offres sur la Plateforme. Nous utilisons également un cookie nécessaire pour enregistrer le consentement  d’un utilisateur concernant notre utilisation de ces cookies, comme décrit dans cette Politique concernant les témoins de connexion.
              </span>
              <span className="optout-con">
                <strong>Désinscription: </strong>Puisque ces cookies sont strictement nécessaires, vous ne pouvez les refuser.
              </span>
            </div>

            <div className="cookie-type-con">
              <span className="type-con">
              Analytique/Performance
              </span>
              <span className="purpose-con">
              <strong>Google Analytics:</strong> utilisé pour le but de gérer la performance du trafic, les interactions des clients avec le Site Web et la Plateforme et les modes d’utilisation. Votre adresse IP et autres informations collectées par ce cookie seront envoyés à et stockée par Google sur des serveurs situés aux États-Unis. <a href="https://policies.google.com/technologies/partner-sites?hl=en-GB&gl=uk/">Cliquez ici</a> pour en apprendre plus. <a href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage">Cliquez ici</a>  pour apprendre plus sur l’expiration de ces cookies.
                    <br></br>
                    <strong>Plusgrade:</strong> Nous avons mis un cookie quand vous utilisez la Plateforme afin d’aider le support à la clientèle, l’utilisation des clients et le suivi des transactions des clients. Ce cookie expirera après 365 jours.
              </span>
              <span className="optout-con">
                <strong>Désinscription: </strong>Vous pouvez bloquer ou supprimer ces cookies en changeant les paramètres du navigateur, comme expliqué dans la section “Vos Choix” ci-dessous. Vous pouvez également empêcher la collection de vos données par Google Analytics sur notre Site Web et la Plateforme en utilisant le <a href="https://tools.google.com/dlpage/gaoptout?hl=en">Google Analytics Opt-out Browser Add-on</a> pour votre navigateur actuel.
              </span>
              
            </div>

            <div className="cookie-type-con">
              <span className="type-con">
              Fonctionnalité
              </span>
              <span className="purpose-con">
                  Utilisé pour fournir des fonctionnements supplémentaires à notre Site Web et la Plateforme ou pour recueillir des informations supplémentaires concernant votre utilisation de notre Site Web et la Plateforme:
                  <br></br>
                  <strong>New Relic:</strong> Utilisé sur notre Plateforme pour les buts de surveiller la performance des systèmes, surveiller la latence du réseau, la duration des chargements et réponses des applications, et l’analyse de compatibilité des navigateurs. Ce cookie expire quand le navigateur/session est fermé.
                  <br></br>
                  <strong>Plusgrade:</strong> Utilisé pour la navigation du Site Web, délivrance du contenu, ou autres besoins fonctionnels.
              </span>
              <span className="optout-con">
                <strong>Désinscription: </strong>Vous pouvez bloquer ou supprimer ces cookies en changeant les paramètres du navigateur, comme expliqué dans la section “Vos Choix” ci-dessous.
              </span>
            </div>
            
          </TableDetailsMobile>

          <h5>Vos Choix</h5>
          <p>
            Veuillez noter que si vous limitez la capacité des Sites Web d’appliquer des cookies, vous pouvez perdre l’accès de certaines parties du Site Web ou de la Plateforme et vous ne pourrez pas prendre avantage de la fonctionnalité complète du Site Web ou de la Plateforme.
          </p>
          <p>
          Sur la plupart des navigateurs web, vous trouverez une section “aide” dans la barre d’outils. Veuillez vous référer à cette section pour des informations expliquant comment recevoir des notifications quand vous recevez un nouveau cookie et comment désactiver les cookies. Veuillez s’il-vous-plaît voir les liens ci-dessous pour apprendre comment modifier les paramètres du navigateur Web sur les navigateurs les plus populaires:
          </p>
          <ul>
          <li> <a href="https://support.microsoft.com/en-us/windows/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d#ie=ie-11">Internet Explorer</a></li>
          <li> <a href="https://support.mozilla.org/en-US/kb/enhanced-tracking-protection-firefox-desktop?esab=a&s=cookies&r=6&as=s&redirectslug=enable-and-disable-cookies-website-preferences&redirectlocale=en-US">Mozilla Firefox</a></li>
          <li> <a href="https://support.google.com/accounts/answer/61416?hl=en">Google Chrome</a></li>
          <li> <a href="https://support.apple.com/en-ca/guide/deployment/depf7d5714d4/web">Apple Safari</a></li>
          </ul>
          <p>
          Si vous accédez le Site Web ou la Plateforme sur votre appareil mobile, il est possible que vous soyiez incapable de contrôler les technologies de suivi dans vos paramètres.
          </p>
          <h5>Changements</h5>
          <p>
            Nous pouvons changer le type de fournisseur de service tiers qui placent les cookies et qui modifient cette Politique sur les témoins de connexion à n’importe quel moment en publiant la version modifiée sur le Site Web ou la Plateforme. À moins qu’il y ait un avis supplémentaire ou un consentement est requis par loi applicable, celui-ci servira d’avis pour ces changements.
          </p>
          <h5>Contactez nous</h5>
          <p>
            Si vous avez des questions ou des préoccupations, s’il-vous-plaît contacter <a style={{"display": "inline-block"}} href={"mailto:dpo@plusgrade.com"}>dpo@plusgrade.com</a>.
          </p>
        </Subsection>
      </CookiePolicy>
    </Content>
  </Layout>
);




export default GDPRPage;
